import { Flex, Image, LinkProps, Text } from '@chakra-ui/core';

import AuthButton from '../Auth/hoc/withAuth0';
import Link from '../Common/Link';
import React from 'react';
import { linkHeader } from '../../constants/typography';
import { useRouter } from 'next/router';

const MenuBar = () => {
  const { route } = useRouter();
  const linkProps: LinkProps = { ...linkHeader, textTransform: 'none', borderBottom: 0, _hover: { borderBottom: 0 } };
  const keylessRoute = '/community/[communityId]/keyless';
  return (
    <Flex
      px="2rem"
      position="fixed"
      top="0"
      height={84}
      width="100%"
      align="center"
      justify="space-between"
      boxShadow="0 0 8px 3px rgba(0,0,0,.1)"
      overflow="hidden"
      background="white"
      color="slategrey"
      zIndex={1000}
    >
      <Link href="/" _hover={{ borderColor: 'none' }} _focus={{ outline: 'none' }} title="Home">
        <Image width="176px" objectFit="contain" alt="Irvine Company Apartments Logo" src="/images/logo.png" />
      </Link>
      {(route === '/' || route === '/community' || route === '/model' || route === keylessRoute) && (
        <Flex align="center">
          <Link href="/" {...linkProps} opacity={route === '/' ? 1 : 0.3}>
            <Text>Prospects</Text>
          </Link>
          <Link
            href="/community"
            ml="32px"
            {...linkProps}
            opacity={route === '/community' || route === keylessRoute ? 1 : 0.3}
          >
            <Text>Community</Text>
          </Link>
        </Flex>
      )}
      <AuthButton />
    </Flex>
  );
};

export default MenuBar;
