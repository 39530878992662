import React, { useEffect, useState } from 'react';

import AuthButton from '../AuthButton';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0ButtonHoc = () => {
  const [text, setText] = useState('Log In');
  const { user, getAccessTokenSilently, loginWithRedirect, logout: auth0Logout, error } = useAuth0();
  const logout = () => {
    localStorage.removeItem('user_email');
    auth0Logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI });
  };

  useEffect(() => {
    if (user || error) {
      setText('Log Out');
    } else {
      setText('Log In');
    }
  }, [user, getAccessTokenSilently, setText]);

  return (
    <AuthButton
      buttonText={text}
      handleLoginLogout={() => (user || error ? logout() : loginWithRedirect({ prompt: 'select_account' }))}
    />
  );
};

export default Auth0ButtonHoc;
