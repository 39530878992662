import { useEffect, useState } from 'react';
import { useBaseMapAdministratorsQuery } from '../graphql/graphql';

const useBaseMapAdmin = (userEmailAddress: string) => {
  const [isBaseMapAdministrator, setIsBaseMapAdministrator] = useState(false);

  const { data: baseMapAdministratorsData, loading: loadingBaseMapAdmin } = useBaseMapAdministratorsQuery({});

  useEffect(() => {
    if (baseMapAdministratorsData && userEmailAddress) {
      const baseMapAdmins = baseMapAdministratorsData?.baseMapAdministrators.emails as string[];
      const userEmail = userEmailAddress || '';
      setIsBaseMapAdministrator(!!baseMapAdmins?.includes(userEmail));
    }
  }, [baseMapAdministratorsData, userEmailAddress]);

  return { isBaseMapAdministrator, loadingBaseMapAdmin };
};

export default useBaseMapAdmin;
