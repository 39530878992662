import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/core';
import { bodyLarge, h1 } from '../../../constants/typography';

import { Close } from '../../icons';
import React, { RefObject } from 'react';
import StyledButton from '../../Common/Button';

export interface InfoModalProps {
  isOpen: boolean;
  closeModal: () => void;
  heading?: string;
  subHeading?: string;
  Footer?: JSX.Element;
  ref?: RefObject<HTMLElement>;
}

// TODO unify this modal with the AlertDialogs used (BlockMapAlertDialog for example)
const InfoModal: React.FC<InfoModalProps> = ({ ref, isOpen, closeModal, heading, subHeading, Footer, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered={true} size="md" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent ref={ref} borderRadius="4px">
        <ModalHeader pb="0">
          <Flex justifyContent="space-between" alignItems="center">
            {heading ? <Text {...h1}>{heading}</Text> : <div />}
            <Button
              variant="ghost"
              title="Close"
              minW="unset"
              h="32px"
              w="32px"
              p="4px"
              _focus={{ outline: 'none' }}
              _hover={{ backgroundColor: 'primaryGray' }}
              onClick={closeModal}
            >
              <Close />
            </Button>
          </Flex>
          {subHeading && <Text {...bodyLarge}>{subHeading}</Text>}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter justifyContent="flex-end">
          {Footer ? (
            Footer
          ) : (
            <StyledButton title="Cancel" onClick={closeModal} w="128px">
              Okay
            </StyledButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default InfoModal;
