import Button from '../Common/Button';
import React from 'react';

interface AuthButtonProps {
  buttonText: string;
  handleLoginLogout: () => void;
}

const AuthButton: React.FC<AuthButtonProps> = ({ buttonText, handleLoginLogout }) => {
  return <Button onClick={handleLoginLogout}>{buttonText}</Button>;
};

export default AuthButton;
